<template>
  <MaximizedDialog
    v-model="isOpen"
    :title="t('Change Storage')"
    @close="emit('close')"
  >
    <ScanStoragesPair
      :expected-storages="expectedStorages"
      class="col"
      @scan="emit('scan', $event)"
    />

    <template #bottom>
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <QBtn
          v-bind="buttonProps"
          icon="mdi-cancel"
          @click="emit('close')"
        >
          {{ t('Back') }}
        </QBtn>
      </ButtonsRow>
    </template>
  </MaximizedDialog>
</template>

<script setup lang="ts">
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import ScanStoragesPair from '@/components/ScanStoragesPair.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import type { Storage } from '@/graphql/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  expectedStorages: [Storage, Storage] | null;
}>();

const emit = defineEmits<{
  close: [];
  scan: [storages: [Storage, Storage]];
}>();

const isOpen = computed({
  get: () => props.expectedStorages !== null,
  set: open => {
    if (!open) {
      emit('close');
    }
  },
});
</script>

<i18n lang="yaml">
ru:
  Change Storage: Изменить ячейку

en:
  Change Storage: Change Storage
</i18n>
